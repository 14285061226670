import React, { useEffect, useState } from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import GameCardGrid from "./GameCardGrid";
import fetchJsonData from "./JsonData";
import Header from "./Header";

const MainContent = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      const fetchedData = await fetchJsonData();
      setData(fetchedData);
    };
    loadData();
  }, []);

  return (
    <Box>
      {data && (
        <Box fontFamily={data.mainFont} bg={data.secondMainBackgroundColor}>
          <Flex
            m="auto"
            direction="column"
            margin="auto"
            width={{ base: "90vw", md: "75vw", lg: "60vw" }} // Responsive width
          >
            <Header />
            <Flex direction="column" width="fit-content">
              <Text
                p={2}
                borderRadius={20}
                backgroundColor="#000000"
                color={data.textWelcomeColor}
                fontSize={{ base: "xs", md: "sm", lg: "md" }} // Responsive font size
                fontWeight="bold"
                width="fit-content"
              >
                {data.mainImageTextWelcome}
              </Text>
              <Text
                color={data.textHelp}
                fontSize={{ base: "xl", md: "2xl", lg: "3xl" }} // Responsive font size
              >
                {data.mainImageTextHelp}
              </Text>
            </Flex>

            <Image
              src={`${data.mainImage}`}
              height="auto"
              width="100%"
              objectFit="contain"
              mt={4}
            />
            <GameCardGrid games={data.Games} />
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default MainContent;
