import React, { useState } from "react";
import { Box, Heading, Grid, GridItem, Button, Flex } from "@chakra-ui/react";
import MainGameCard from "./MainGameCard";

const GamesCardGrid = ({ games = [] }) => {
  // Filter enabled games
  const enabledGames = games.filter((g) => g.enabled);

  // Sort the games based on the 'order' property (ascending)
  const sortedGames = enabledGames.sort((a, b) => a.order - b.order);

  const [visibleGames, setVisibleGames] = useState(10);

  const loadMoreGames = () => {
    setVisibleGames((prev) => Math.min(prev + 10, sortedGames.length));
  };

  // Get the visible game cards based on the state
  const visibleGameCards = sortedGames.slice(0, visibleGames);

  return (
    <Box
      my={4}
      backgroundColor="#fff"
      p={4}
      boxShadow="2xl" // Predefined shadow
      borderRadius="1%"
      border="2px solid #ddd"
    >
      <Heading as="h2" size="lg" p={3}>
        GAMES
      </Heading>

      <Grid
        templateColumns={{
          base: "repeat(2, 1fr)", // 2 columns for smaller screens
          md: "repeat(3, 1fr)", // 3 columns for medium screens
          lg: "repeat(5, 1fr)", // 5 columns for large screens
        }}
        gap={{ base: 2, md: 4, lg: 6 }}
      >
        {/* First game occupies the entire first row on small/medium screens */}
        {visibleGameCards.length > 0 && (
          <GridItem
            colSpan={{
              base: 2, // Span 2 columns on smaller screens
              md: 3, // Span 3 columns on medium screens
              lg: 2, // Span 2 columns on large screens
            }}
            rowSpan={{
              base: 1, // Occupy only 1 row on smaller screens
              lg: 2, // Occupy 2 rows on large screens
            }}
          >
            <MainGameCard key={0} game={visibleGameCards[0]} />
          </GridItem>
        )}

        {/* Remaining games */}
        {visibleGameCards.slice(1).map((game, index) => (
          <GridItem key={index + 1}>
            <MainGameCard game={game} />
          </GridItem>
        ))}
      </Grid>

      {visibleGames < sortedGames.length && (
        <Flex justifyContent="center" mt={8}>
          <Button colorScheme="blue" onClick={loadMoreGames}>
            Load More
          </Button>
        </Flex>
      )}
    </Box>
  );
};

export default GamesCardGrid;
