import React, { useEffect, useState } from "react";
import { Box, Link, Image, Text, Flex } from "@chakra-ui/react";
import fetchJsonData from "./JsonData";

const Footer = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      const fetchedData = await fetchJsonData();
      setData(fetchedData);
    };
    loadData();
  }, []);

  if (
    !data ||
    !data.footerLinks ||
    !data.footerLinks[0] ||
    !data.footerLinks[1]
  ) {
    return null; // Return null if data or links are not available yet
  }

  return (
    <Box bg="gray.100" py={6} backgroundColor={data.footerBackgroundColor}>
      <Flex direction="column" align="center" textAlign="center">
        {/* Footer Logo */}
        <Box mb={4}>
          <Link href={data.customerPageUrl} isExternal>
            <Image
              src={data.mainFooterLogo}
              alt="Footer Logo"
              maxWidth="150px"
            />
          </Link>
        </Box>

        {/* Footer Credential */}
        <Text fontSize="sm" color={data.footerTextColor}>
          {data.footerCredential}
        </Text>

        {/* Privacy and Rights Links */}
        <Flex mt={4} gap={2} fontSize="sm" color={data.footerTextColor}>
          {data.footerLinks[0] && (
            <Link href={data.footerLinks[0].targetUrl} isExternal>
              {data.footerLinks[0].label}
            </Link>
          )}
          {data.footerLinks[1] && (
            <Link href={data.footerLinks[1].targetUrl} isExternal>
              {data.footerLinks[1].label}
            </Link>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default Footer;
