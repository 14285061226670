import React, { useState } from "react";
import { Box, Link, Image, Text } from "@chakra-ui/react";

const MainGameCard = ({ game }) => {
  const hasMultipleUrls = game.targetUrls.length > 1;

  // Selected options
  const [selectedOption] = useState(
    hasMultipleUrls ? game.targetUrls[0].label : null
  );

  const selectedTargetUrl = hasMultipleUrls
    ? game.targetUrls.find((option) => option.label === selectedOption)
        .targetUrl
    : game.targetUrls[0].targetUrl;

  return (
    <Box
      position="relative"
      transition="transform 0.3s ease"
      borderRadius="5%"
      overflow="hidden"
    >
      <Link href={selectedTargetUrl} isExternal>
        <Image src={game.logoUrl} alt={game.hoverTitle} objectFit="contain" />
      </Link>
      <Text
        p={{ base: 1, md: 2, lg: 3 }} // Adjusts padding based on screen size
        background="#000"
        color="white"
        fontWeight="bold"
        fontSize={{ base: "sm", md: "md", lg: "lg" }} // Responsive font sizes
        textAlign="center"
      >
        {game.title}
      </Text>
    </Box>
  );
};

export default MainGameCard;
